import { h } from 'preact'
import { Router } from 'preact-router'

import Player from '../routes/player'

const App = () => (
  <div id="app">
    <Router>
      <Player path="/" />
    </Router>
  </div>
)

export default App
